@import "../../node_modules/normalize.css/normalize.css";

@import "./core/global";

$blue: #4871e4;
$red: #df5151;

*,
*:before,
*:after {
    box-sizing: border-box;
}

.frame {
    display: flex;
    width: 100vw;

    &__sidebar {
        width: 400px;
        background-color: white;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    }

    &__preview {
        flex-grow: 1;
        margin-left: 400px;
    }
}

.sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-shadow: inset 0 0 5px rgba(black, 0.1);
    background-color: hsl(0, 0, 95%);

    &__header {
        overflow: hidden;
        padding: 30px;
        flex-shrink: 0;
        border-bottom: 1px solid hsl(0, 0, 85%);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            max-width: 150px;
        }
    }

    &__body {
        padding: 30px;
        flex-grow: 1;
        flex-shrink: 1;
        overflow-y: auto;

        > * {
            position: relative;
            padding: 30px 0;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: hsl(0, 0, 85%);
            }

            &:last-child {
                padding-bottom: 0;
                &::after {
                    display: none;
                }
            }

            &:first-child {
                padding-top: 0;
            }
        }
    }

    &__footer {
        flex-shrink: 0;
        border-top: 1px solid hsl(0, 0, 85%);
        padding: 30px;
        .button {
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }

    &__title {
        margin: 0;
    }
}

.edm-preview {
    width: 100%;
    max-width: 600px;
    display: block;
    margin: 0 auto;

    > * {
        transition: 200ms ease all;
    }

    .focussed {
        position: relative;
        z-index: 1;
        box-shadow: 0 0 0 2px $red;
    }

    .not-focussed {
        opacity: 0.25;
    }

    img {
        display: block;
        padding: 0;
        width: 100%;
    }
}

.button {
    display: block;
    width: 100%;
    border: 0;
    background-color: $blue;
    padding: 25px;
    text-align: center;
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    transition: 200ms ease all;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 600;

    &:hover {
        opacity: 0.9;
    }

    &[disabled] {
        opacity: 0.5;
    }

    &--red {
        background-color: $red;
    }

    &--small {
        padding: 10px 12px;
        font-size: 12px;
        display: inline-block;
        width: auto;
    }
}

.orderer {
    margin-bottom: -10px;
    > * {
        margin-bottom: 10px;
    }
}

.drag-item {
    padding: 10px;
    background-color: hsl(0, 0, 100%);
    display: flex;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(black, 0.07);
    padding-left: 0;

    &__content {
        flex-grow: 1;
        flex-shrink: 1;
    }

    &__name {
        margin-bottom: 10px;
        font-weight: bold;
        word-break: break-word;
        font-size: 12px;
        color: hsl(0, 0, 20%);
    }

    &__url-input {
        input {
            padding: 10px;
            border: 1px solid hsl(0, 0, 90%);
            width: 100%;
            display: block;
            border-radius: 3px;
            background-color: hsl(0, 0, 95%);
        }
    }

    &__indicator {
        right: 17px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        &__inner {
            span {
                display: block;
                width: 15px;
                height: 2px;
                background-color: rgba(black, 0.2);
                margin-bottom: 2px;
            }
        }
    }
}

.instructions {
    border: 1px solid hsl(0, 0, 90%);
    background-color: white;
    padding: 20px;

    h3 {
        margin-bottom: 10px;
        font-size: 16px;
    }

    ul,
    ol {
        padding: 0;
        margin: 0;
        padding-left: 1.5em;
    }

    li,
    p {
        font-size: 14px;
        margin: 5px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.tree-input {
    margin: 30px 0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    > * {
        display: block;
        margin: 10px 0;

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__label {
        font-weight: bold;
        font-size: 16px;
    }

    &__input {
        width: 100%;
        padding: 10px;
        border: 1px solid hsl(0, 0, 85%);
        background-color: white;
        border-radius: 3px;

        .tree-input.disabled & {
            opacity: 0.5;
        }
    }

    &__info {
        font-size: 12px;
    }

    &__error {
        font-size: 12px;
        color: rgb(223, 81, 81);
    }
}

hr {
    margin: 30px 0;
    border: 0;
    height: 1px;
    background-color: hsl(0, 0, 85%);
}

.preview-info {
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: center;

    &__inner {
        background-color: white;

        > * {
            margin: 0.5em 0;
        }
    }
}

.modal {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &__inner {
        padding: 20px;
        margin: auto 0;
        max-width: 500px;
        width: 100%;
    }
}

.login {
    border-radius: 3px;
    background-color: white;
    box-shadow: 0 5px 10px rgba(black, 0.1);
    border: 1px solid hsl(0, 0, 85%);
    padding: 50px;
    text-align: center;

    &__lockup {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        justify-content: center;
    }

    &__logo {
        // width: 40px;
        width: 100%;
        max-width: 200px;
        margin-right: 10px;
    }

    &__title {
    }
}
